<template>
  <div class="page">
    <el-form
      :model="formInline"
      label-position="right"
      class="demo-form-inline"
      label-width="100px"
    >
      <div class="myTitle">产品基本信息</div>
      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="产品名称">
            <el-select
              size="medium"
              filterable
              v-model="formInline.typeId"
              :placeholder="$t('global.请选择')"
            >
              <el-option
                v-for="item in []"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="单位">
            <el-input
              disabled
              size="medium"
              v-model="formInline.user"
              placeholder="审批人"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="产品编码">
            <el-input
              disabled
              size="medium"
              v-model="formInline.user"
              placeholder="审批人"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="7">
          <el-form-item label="计算类型">
            <el-radio v-model="formInline.type" :label="1">按重量计算</el-radio>
            <el-radio v-model="formInline.type" :label="2">其他</el-radio>
          </el-form-item>
        </el-col>
        <el-col :span="7" class="flex">
          <el-form-item label="质量换算">
            <el-input size="medium" v-model="formInline.user"></el-input>
          </el-form-item>
          <span class="unit"> kg </span>
        </el-col>
        <el-col :span="7">
          <el-form-item label="备注">
            <el-input
              max-length="500"
              type="textarea"
              size="medium"
              :autosize="{ minRows: 3, maxRows: 4 }"
              v-model="formInline.remark"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="myTitle">物料信息</div>
    </el-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      formInline: {}
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.myTitle {
  font-size: 20px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  margin-bottom: 24px;
  &::before {
    content: "";
    display: inline-block;
    position: relative;
    top: 3px;
    margin-right: 8px;
    width: 4px;
    height: 22px;
    background: #09a666;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
  }
}
.page {
  margin: 24px auto;
  width: 1400px;
  background-color: #fff;
  min-height: calc(100vh - 110px);
  padding: 30px;
  /deep/.demo-form-inline {
    .flex {
      position: relative;
      .unit {
        position: absolute;
        top: 15px;
        right: -8px;
      }
    }
  }
}
</style>